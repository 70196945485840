<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="下月服务计划" name="first">
        <div class="flex marl_30">
          <div style="
              width: 100px;
              line-height: 40px;
              position: relative;
              font-size: 14px;
            ">
            关键词搜索
          </div>
          <div class="flex" style="
              width: 400px;
              height: 40px;
              border: 1px solid #e6e6e6;
              border-radius: 6px;
            ">
            <input type="text" v-model="search" placeholder="请输入内容" style="
                padding: 10px;
                border: none;
                  outline:none;
                width: 370px;
                border: 1px solid transparent;
                border-radius: 6px;
              " 
              />
            <!-- <el-input placeholder="请输入内容"></el-input> -->
            <i class="el-icon-search" style="
                font-size: 20px;
                line-height: 40px;
                border: 1px solid transparent;
                border-radius: 6px;
              "></i>
          </div>

          <div class="marl_30" style="
              width: 198px;
              height: 40px;
              background: #ff6618;
              border-radius: 6px;
              line-height: 40px;
              text-align: center;
              color: #fff;
            " @click="postcurrency()">
            添加下月服务计划
          </div>
        </div>
        <div>
          <el-table :data="TableData" style="width: 100%">
            <el-table-column prop="ID" label="ID" min-width="180" align="center"></el-table-column>
            <el-table-column prop="activityName" label="活动名称" min-width="180" align="center"></el-table-column>
            <el-table-column prop="activityType" label="服务类型" min-width="180" align="center"></el-table-column>
            <el-table-column prop="name" label="填报人" min-width="180" align="center">
            </el-table-column>
            <el-table-column align="center" prop="mobile" label="联系电话" min-width="180"></el-table-column>
            <el-table-column prop="information" label="月报详情" min-width="180" align="center">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="looklist(scope.row)">查看月报详情</el-button>
              </template>
            </el-table-column>
            <el-table-column prop="submit_time" label="报送月份" min-width="180" align="center">
            </el-table-column>
            <el-table-column prop="create_time" label="报送时间" min-width="180" align="center">
            </el-table-column>
            <el-table-column prop="content" label="操作" min-width="180" align="center">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="goAddmonthly(scope.row)">修改月报</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <el-dialog title="下月服务计划详情" :visible.sync="dialogVisible" width="600px" center>
          <div>
            <div style="border-bottom: 3px solid #ffffff; padding: 10px 0px" class="size_18">
              填报人：{{ lookmessage.name }}
            </div>
            <div style="border-bottom: 3px solid #ffffff; padding: 10px 0px" class="size_18">
              手机号：{{ lookmessage.mobile }}
            </div>
            <div style="padding: 10px 0px" class="size_18">
              服务类型:{{ lookmessage.num ? lookmessage.num : 0 }}
            </div>
            <div style="padding: 10px 0px" class="size_18">
              活动名称：{{ lookmessage.activity_name }}
            </div>
            <div style="padding: 10px 0px" class="size_18">
              报送月份：{{ lookmessage.submit_time }}
            </div>
            <div style="padding: 10px 0px" class="size_18">
              活动时间：{{ lookmessage.start_time }}
            </div>
            <div style="padding: 10px 0px" class="size_18">
              地址：{{ lookmessage.address }}
            </div>
            <div style="padding: 10px 0px" class="size_18">
              主办单位:{{ lookmessage.host_unit }}
            </div>
            <div style="padding: 10px 0px" class="size_18">
              承办单位:{{ lookmessage.undertake_unit }}
            </div>
            <div style="padding: 10px 0px" class="size_18">
              联系人:{{ lookmessage.contact }}
            </div>
            <div style="padding: 10px 0px" class="size_18">
              联系电话:{{ lookmessage.activity_mobile }}
            </div>
            <div style="padding: 10px 0px" class="size_18">
              活动主题:{{ lookmessage.title }}
            </div>
            <div style="padding: 10px 0px" class="size_18">
              典型案例介绍:{{ lookmessage.content }}
            </div>
          </div>
        </el-dialog>

        <el-pagination v-if="TableData.length != 0" @size-change="handleSizeChange"
          @current-change="handleCurrentChange" :current-page="paginations.page_index"
          :page-sizes="paginations.page_sizes" :page-size="paginations.page_size" :layout="paginations.layout"
          :total="paginations.total">
        </el-pagination>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeName: "first",
      search: "",
      allTableData: [],
      paginations: {
        page_index: 1, // 当前位于哪页
        total: 10, // 总数
        page_size: 10, // 1页显示多少条
        page_sizes: [10, 20, 50, 90], //每页显示多少条
        layout: "total, sizes, prev, pager, next, jumper", // 翻页属性
      },
      TableData: [],
      total: "",
      dialogVisible: false,
      lookmessage: "",
    };
  },
  created() {
    this.getmonthly();
  },
  methods: {
    //认领
    postcurrency() {
      let data = {
        token: localStorage.eleToken,
      };
      this.$post("mechanism_currency", data).then((res) => {
        console.log(res, "res");
        if (res.result.audit_status === 2) {
          this.goAddmonthly();
        } else if (
          res.result.audit_status === 0 ||
          res.result.audit_status === 3
        ) {
          this.authentication();
        } else if (res.result.audit_status === 1) {
          this.$message({
            type: "info",
            message: "审核中",
          });
        }
      });
    },
    // 认证
    authentication() {
      let data = {
        token: localStorage.eleToken,
      };
      this.$get("/enterprise_user_status", data).then((res) => {
        console.log(res, "res");
        if (res.status === 1) {
          this.gonext();
        } else if (res.status === 2) {
          // location.href = "http://sso.zhenchangkj.com:9000/realname/index";
          // location.href = "http://sso.smeqh.cn:9000/realname/index";
          // this.$message({
          //   type: "info",
          //   message: "您的账号还未实名认领，请认领通过之后再进行下一步操作",
          // });
        }
      });
    },

    //查看详情
    looklist(item) {
      let data = {
        token: localStorage.eleToken,
        id: item.ID,
        type: 3,
      };
      this.$post("/monthly_lst_d", data).then((res) => {
        this.dialogVisible = true;
        this.lookmessage = res.result;
        console.log(res, "res");
      });
    },

    getmonthly() {
      let data = {
        token: localStorage.eleToken,
        keyword: this.search,
        type: 3,
        page: this.paginations.page_index,
        limit: this.paginations.page_size,
      };
      this.$post("/monthly_lst", data).then((res) => {
        this.allTableData = res.result;
        this.total = res.count;
        console.log(this.allTableData, "this.allTableData");
        this.setPaginations();
      });
    },
    gonext() {
      this.$router.push("/Addnextmonthly");
      console.log("111");
    },
    //修改月报
    goAddmonthly(item) {
      console.log(item,'item')
        this.$router.push({ path: "/Addnextmonthly", query: { ID: item.ID } });
      


    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    handleSizeChange(page_size) {
      console.log(page_size, "page_size");
      this.paginations.page_index = 1;
      this.paginations.total = this.total;
      this.TableData = this.allTableData.filter((item, index) => {
        return index < page_size;
      });
    },

    // 分页

    handleCurrentChange(page) {
      console.log(page, "handleCurrentChangepage");
      //获取当前页
      let index = this.paginations.page_size * (page - 1);
      //获取数据总数
      let nums = this.paginations.page_size * page;
      //容器
      let tables = [];
      for (let i = index; i < nums; i++) {
        if (this.allTableData[i]) {
          tables.push(this.allTableData[i]);
        }
        this.TableData = tables;
      }
    },

    //重置分页
    setPaginations() {
      // 总页数
      this.paginations.total = this.allTableData.length;
      this.paginations.page_index = 1;
      this.paginations.page_size = 10;
      //设置默认的分页数据
      if (this.allTableData.length != 0) {
        this.TableData = this.allTableData.filter((item, index) => {
          return index < this.paginations.page_size;
        });
      } else {
        this.TableData = [];
      }
    },
  },
};
</script>

<style lang="less" scoped>
::v-deep .el-pagination {
  text-align: center;
  margin-top: 20px;
}

//修改菜单颜色
/deep/.el-tabs__item.is-active {
  color: #ff7129 !important;
}

/deep/.el-tabs__active-bar {
  background-color: #ff7129 !important;
}

/deep/.el-tabs__item:hover {
  color: #ff7129 !important;
}
</style>